<template>
	<resources-index :resources="resources" type="video" plural="videos"></resources-index>
</template>

<script>

import ResourcesIndex from '@/views/resources/Index'
import {sync} from "vuex-pathify";
export default {
	name: "VideoIndex",
	components: {
		ResourcesIndex
	},
	computed: {
		resources: sync('videos/videos'),
	},
}
</script>